<template>
  <h1>ПРОГРАМА МІНІКУРСУ</h1>
  <div class="lessons-container">
    <div class="container">
      <!-- Верхній блок з текстом -->
      <div class="top-section">
        <div class="yellow-box">
          <p class="text-content">УРОК 1</p>
        </div>
        <p class="bottom-text">
          Система Без Зусиль: що це і як вона допоможе тобі досягти успіху на крипторинку. Вступний урок.
        </p>
      </div>

      <!-- Блок з двома фото -->
      <div class="image-section">
        <!-- <div class="left-image">
          <img src="@/assets/img/l_main.png" alt="Left Image" />
        </div> -->
        <div class="right-image">
          <img src="@/assets/img/1.png" alt="Right Image" />
        </div>
      </div>
    </div>
    <div class="container">
      <!-- Верхній блок з текстом -->
      <div class="top-section">
        <div class="yellow-box">
          <p class="text-content">УРОК 2</p>
        </div>
        <p class="bottom-text">Трейдинг та інвестиції: як усе влаштовано? Вся правда про ринок!</p>
      </div>

      <!-- Блок з двома фото -->
      <div class="image-section">
        <!-- <div class="left-image">
          <img src="@/assets/img/l_main.png" alt="Left Image" />
        </div> -->
        <div class="right-image">
          <img src="@/assets/img/2.png" alt="Right Image" />
        </div>
      </div>
    </div>
    <div class="container">
      <!-- Верхній блок з текстом -->
      <div class="top-section">
        <div class="yellow-box">
          <p class="text-content">УРОК 3</p>
        </div>
        <p class="bottom-text">Цикли ринку: коли купувати, коли продавати і як не втрачати гроші</p>
      </div>

      <!-- Блок з двома фото -->
      <div class="image-section">
        <!-- <div class="left-image">
          <img src="@/assets/img/l_main.png" alt="Left Image" />
        </div> -->
        <div class="right-image">
          <img src="@/assets/img/3.png" alt="Right Image" />
        </div>
      </div>
    </div>
    <div class="container">
      <!-- Верхній блок з текстом -->
      <div class="top-section">
        <div class="yellow-box">
          <p class="text-content">УРОК 4</p>
        </div>
        <p class="bottom-text">Формула зростання капіталу: як складний відсоток створює багатство</p>
      </div>

      <!-- Блок з двома фото -->
      <div class="image-section">
        <!-- <div class="left-image">
          <img src="@/assets/img/l_main.png" alt="Left Image" />
        </div> -->
        <div class="right-image">
          <img src="@/assets/img/4.png" alt="Right Image" />
        </div>
      </div>
    </div>
    <div class="container">
      <!-- Верхній блок з текстом -->
      <div class="top-section">
        <div class="yellow-box">
          <p class="text-content">УРОК 5</p>
        </div>
        <p class="bottom-text">Розумний крипто-портфель: як збалансувати ризики та прибуток</p>
      </div>

      <!-- Блок з двома фото -->
      <div class="image-section">
        <!-- <div class="left-image">
          <img src="@/assets/img/l_main.png" alt="Left Image" />
        </div> -->
        <div class="right-image">
          <img src="@/assets/img/5.png" alt="Right Image" />
        </div>
      </div>
    </div>
    <div class="container">
      <!-- Верхній блок з текстом -->
      <div class="top-section">
        <div class="yellow-box">
          <p class="text-content">УРОК 6</p>
        </div>
        <p class="bottom-text">Як читати ринок за допомогою індикаторів: точка входу та виходу</p>
      </div>

      <!-- Блок з двома фото -->
      <div class="image-section">
        <!-- <div class="left-image">
          <img src="@/assets/img/l_main.png" alt="Left Image" />
        </div> -->
        <div class="right-image">
          <img src="@/assets/img/6.png" alt="Right Image" />
        </div>
      </div>
    </div>
    <div class="container last">
      <!-- Верхній блок з текстом -->
      <div class="top-section">
        <div class="yellow-box">
          <p class="text-content">УРОК 7</p>
        </div>
        <p class="bottom-text">Як заробляти більше ніж 99% за допомогою cryptonite Prime?</p>
      </div>

      <!-- Блок з двома фото -->
      <div class="image-section">
        <!-- <div class="left-image">
          <img src="@/assets/img/l_main.png" alt="Left Image" />
        </div> -->
        <div class="right-image">
          <img src="@/assets/img/7.png" alt="Right Image" />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <!-- Верхній блок з текстом -->
    <div class="top-section">
      <div class="yellow-box">
        <p class="text-content">БОНУС</p>
      </div>
      <p class="bottom-text">Біржі для трейдингу та інвестування: як обрати найкращу для себе</p>
    </div>

    <!-- Блок з двома фото -->
    <div class="image-section">
      <div class="right-image"></div>
    </div>
  </div>
  <div class="container">
    <!-- Верхній блок з текстом -->
    <div class="top-section">
      <div class="yellow-box">
        <p class="text-content">БОНУС</p>
      </div>
      <p class="bottom-text">Подарунок: 3 професійні індикатори від сryptonite – доступ на 1 рік</p>
    </div>

    <!-- Блок з двома фото -->
    <div class="image-section">
      <div class="right-image"></div>
    </div>
  </div>
  <div class="container">
    <!-- Верхній блок з текстом -->
    <div class="top-section">
      <div class="yellow-box">
        <p class="text-content">БОНУС</p>
      </div>
      <p class="bottom-text">TradingView для новачків і профі: як налаштувати платформу під себе</p>
    </div>

    <!-- Блок з двома фото -->
    <div class="image-section">
      <div class="right-image"></div>
    </div>
  </div>
  <div class="container">
    <!-- Верхній блок з текстом -->
    <div class="top-section">
      <div class="yellow-box">
        <p class="text-content">БОНУС</p>
      </div>
      <p class="bottom-text">Розбір кейсів учасників Cryptonite Prime: реальні історії та цінний досвід</p>
    </div>

    <!-- Блок з двома фото -->
    <div class="image-section">
      <div class="right-image"></div>
    </div>
  </div>
</template>

<script></script>

<style scoped lang="scss">
h1 {
  color: $text-grey;
  font-family: 'Gilroy-Bold';
  text-align: center;
  margin-top: 60px;
  margin-bottom: 10px;
  @include text-style($h1-size, 700);
}
.container {
  max-width: 900px;
  height: auto;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  border-radius: 15px;
  background-color: $bg-component;
}
.last {
  margin-bottom: 50px;
}
.top-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.yellow-box {
  background-color: $yellow;
  padding: 6px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  min-width: 80px;
  height: 60px;
}

.text-content {
  font-family: 'Gilroy-EBold';
  font-weight: 800;
  margin: 0;
  color: $black;
  font-size: clamp(18px, 3vw, 32px);
}

.bottom-text {
  margin: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  color: white;
  font-weight: 100;
  font-family: 'Gilroy-reg';
  font-style: normal;
  line-height: normal;
  font-size: clamp(16px, 3vw, 32px);
  line-height: 1.08;
}
.lessons-container {
  margin-bottom: 25px;
}
.image-section {
  display: flex;
  width: 100%; /* Забезпечує, що зображення займають всю ширину */
  height: auto;
  max-height: 440px;
  overflow: hidden;
  align-items: stretch; /* Зображення заповнюють висоту */
}

.right-image {
  display: flex;
  justify-content: center; /* Вирівнювання по центру */
  align-items: center; /* Вирівнювання по центру */
}

// .left-image {
//   flex: 0 0 30%; /* Ліве зображення займає 30% ширини */
//   padding: 0;
// }

.right-image {
  width: 100%; /* Тепер праве зображення займає всю ширину */
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-image img {
  width: 100%;
  height: auto;
  // border-radius: 20px; /* Якщо потрібно зберегти округлені краї */
  object-fit: cover;
  border-radius: 20px;
}

@media (max-width: 1000px) {
  .container {
    width: 90%;
    padding: 12px;
    margin-bottom: 15px;
    gap: 0;
  }

  .right-image img {
    border-top-left-radius: 2px; /* Заокруглення лівого фото */
    border-bottom-left-radius: 2px; /* Заокруглення лівого фото */
    border-top-right-radius: 6px; /* Заокруглення правого фото */
    border-bottom-right-radius: 6px; /* Заокруглення правого фото */
  }
  .top-section {
    gap: 0;
    .yellow-box {
      height: auto;
      padding: 2px;
      border-radius: 5px;
    }
  }
}
</style>
