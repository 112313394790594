import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComp = _resolveComponent("HeaderComp")!
  const _component_SliderComp = _resolveComponent("SliderComp")!
  const _component_EffortlessComp = _resolveComponent("EffortlessComp")!
  const _component_ForWhomCourse = _resolveComponent("ForWhomCourse")!
  const _component_CourseSpeakerCard = _resolveComponent("CourseSpeakerCard")!
  const _component_CourseComponentCard = _resolveComponent("CourseComponentCard")!
  const _component_TabComponent = _resolveComponent("TabComponent")!
  const _component_ExampleComponent = _resolveComponent("ExampleComponent")!
  const _component_StageComponent = _resolveComponent("StageComponent")!
  const _component_ReviewComponent = _resolveComponent("ReviewComponent")!
  const _component_HowCourseComponent = _resolveComponent("HowCourseComponent")!
  const _component_FaqComponent = _resolveComponent("FaqComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComp),
    _createVNode(_component_SliderComp),
    _createVNode(_component_EffortlessComp),
    _createVNode(_component_ForWhomCourse),
    _createVNode(_component_CourseSpeakerCard),
    _createVNode(_component_CourseComponentCard),
    _createVNode(_component_TabComponent),
    _createVNode(_component_ExampleComponent),
    _createVNode(_component_StageComponent),
    _createVNode(_component_ReviewComponent),
    _createVNode(_component_HowCourseComponent),
    _createVNode(_component_FaqComponent)
  ]))
}